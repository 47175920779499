import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
//antd组件
import {
  Row, Col,
} from 'antd';
// 组件
import PageBg from '../../components/PageBg/PageBg'
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
// 样式
import './Lexin.less'

const ScrollOverPack = ScrollAnim.OverPack;

class Lexin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //遇到问题
      wentiList: [
        {
          title: "无法提供发票",
          tips: "大量个人不懂繁杂的开票流程无法提供发票",
          img: require("../../static/images/lexin/wenti/wenti1.png"),
        }, {
          title: "税负重/成本高",
          tips: "劳务报酬报个税税率最高达45%企业和个人伤不起！",
          img: require("../../static/images/lexin/wenti/wenti2.png"),
        }, {
          title: "三流不合一",
          tips: "私对私转账，找朋友公司开票虚开发票风险",
          img: require("../../static/images/lexin/wenti/wenti3.png"),
        }
      ],
      //为什么选择
      whyList: [
        {
          title: "政策法规保障",
          tips: "税务机关制定委托代征机构",
          img: require("../../static/images/lexin/why/why1.png"),
        }, {
          title: "资深实力雄厚",
          tips: "获得国内知名投资机构投资",
          img: require("../../static/images/lexin/why/why2.png"),
        }, {
          title: "合法合规有保障",
          tips: "拥有会计事务所专业团队设计合规方案",
          img: require("../../static/images/lexin/why/why3.png"),
        }, {
          title: "资金有保障",
          tips: "雄厚资金，平安银行监管安全可靠",
          img: require("../../static/images/lexin/why/why4.png"),
        }
      ],
      whyActiveIndex: "",
      //专属解决方案
      zhuanshuList: [
        {
          title: "物流行业",
          img: require("../../static/images/lexin/zhuanshujiejue/wlhy.png"),
          bgImg: require('../../static/images/lexin/zhuanshujiejue/wlhy_bg.png'),
          size: { w: 114, h: 92 },
          descp: "帮助物流公司将司机收益合理化的同时，解决物流公司增值税抵扣链条断裂问题。提高合规性，降低成本，提升物流行业的核心竞争力",
        }, {
          title: "直播行业",
          img: require("../../static/images/lexin/zhuanshujiejue/zbhy.png"),
          bgImg: require('../../static/images/lexin/zhuanshujiejue/zbhy_bg.png'),
          size: { w: 113, h: 86 },
          descp: "帮助直播平台将主播收益合理化的同时解决直播平台涉税风险。目前已与多家主播平台及主播经纪公司合作，合法合规解决主播平台产生的税务问题",
        }, {
          title: "医疗行业",
          img: require("../../static/images/lexin/zhuanshujiejue/ylhy.png"),
          bgImg: require('../../static/images/lexin/zhuanshujiejue/ylhy_bg.png'),
          size: { w: 102, h: 102 },
          descp: "对医药行业的人力资源成本进行合理筹划，适当降低人力资源成本，同时适当降低高收入群体的个人所得税，助力降低药价，有利于国计民生",
        }, {
          title: "外卖平台",
          img: require("../../static/images/lexin/zhuanshujiejue/wmpt.png"),
          bgImg: require('../../static/images/lexin/zhuanshujiejue/wmpt_bg.png'),
          size: { w: 120, h: 120 },
          descp: "帮助外卖平台解决无法提供合理入账凭证、增值税无法抵扣、自由职业者报税不合规问题",
        }, {
          title: "自媒体",
          img: require("../../static/images/lexin/zhuanshujiejue/zmt.png"),
          bgImg: require('../../static/images/lexin/zhuanshujiejue/zmt_bg.png'),
          size: { w: 120, h: 120 },
          descp: "帮助自媒体行业解决签约自媒体税负高、报税不合理、平台无法取得合理的入账凭证等问题",
        },
      ],
      zhuanshuActiveIndex: "",
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(3))
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  // 为什么选择active
  whyActive = (index) => {
    this.setState({ whyActiveIndex: index })
  }
  whyActiveOut = () => {
    this.setState({ whyActiveIndex: "" })
  }
  //专属方案active
  zhuanshuActive = (index) => {
    this.setState({ zhuanshuActiveIndex: index })
  }
  zhuanshuActiveOut = () => {
    this.setState({ zhuanshuActiveIndex: "" })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      wentiList, //遇到问题
      whyList, //为什么选择我们
      zhuanshuList,
    } = this.state

    //遇到问题
    let wenti = wentiList.map((obj, index) => (
      <Col key={index} span={8}>
        <div key={index} className="flexColumn">
          <QueueAnim type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + index * 220}
            duration={450 + index * 600}
          >
            <div key={index} className="flexColumn flexJCenter">
              <img src={obj.img} alt='' style={{ width: "112px", height: "112px" }} />
              <div style={{ fontSize: "14px", marginTop: "25px", marginBottom: "5px" }}>{obj.title}</div>
              <div style={{ fontSize: "12px", color: "#4a4a4a" }}>{obj.tips}</div>
            </div>
          </QueueAnim>
        </div>
      </Col>
    ))
    //为什么选择
    let why = whyList.map((obj, index) => (
      <div key={index}
        className={`flexCenter whyItem${index === this.state.whyActiveIndex ? " active" : ""}`}
        onMouseEnter={() => { this.whyActive(index) }}
        onMouseLeave={this.whyActiveOut}
      >
        <img src={obj.img} alt='' style={{ width: '79px', height: "79px", margin: "0px 30px 0 49px" }} />
        <div>
          <div style={{ fontSize: "17px" }}>{obj.title}</div>
          <div style={{ fontSize: "14px", color: "#4A4A4A" }}>{obj.tips}</div>
        </div>
      </div>
    ))
    //解决方案
    let jiejueDom = (
      <div className="common_wrap flex flexJCenter">
        <div style={{ flex: "1 0 0" }}>
          <QueueAnim className="fanganItem"
            type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10}
            duration={600}
            style={{ textAlign: "right" }}
          >
            <div key="1">
              <div className="fanganTitle">新零售兼职营销工具</div>
              <div className="fanganTips">服务对象：灵活兼职个人</div>
              <div className="fanganTips">特点：专业、匹配、灵活</div>
            </div>
          </QueueAnim>
          <QueueAnim className="fanganItem"
            type={['left', 'right']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 2}
            duration={600}
            style={{ textAlign: "right", marginTop: "28px" }}
          >
            <div key="1">
              <div className="fanganTitle">奖金支付透明</div>
              <div className="fanganTips">针对对象：平台兼职人员</div>
              <div className="fanganTips">特点：数据透明、兑付实时、方便快捷</div>
            </div>
          </QueueAnim>
        </div>
        <div className="flexCenter flexJCenter posiRe" style={{ height: "257px", width: "430px" }}>
          <img src={require("../../static/images/lexin/fangan/lexin_fangan.png")} alt='' style={{ position: "absolute", left: "0", top: "0", width: "100%", height: "100%" }} />
          <div style={{ position: "relative", zIndex: 33, fontSize: "17px", color: "#000" }}>爱灵活</div>
          {/* 旋转圆圈 */}
          <div className="yuanWrap">
            {/* 小到大 */}
            <img className='positionCenter yuan yuan1' src={require('../../static/images/lexin/fangan/yuan1.png')} alt="" style={{ width: "178px", height: "178px" }} />
            <img className='positionCenter yuan yuan2' src={require('../../static/images/lexin/fangan/yuan2.png')} alt="" style={{ width: "190px", height: "190px" }} />
            <img className='positionCenter yuan yuan3' src={require('../../static/images/lexin/fangan/yuan3.png')} alt="" style={{ width: "202px", height: "202px" }} />
            <img className='positionCenter yuan yuan4' src={require('../../static/images/lexin/fangan/yuan4.png')} alt="" style={{ width: "214px", height: "214px" }} />
            <img className='positionCenter yuan yuan5' src={require('../../static/images/lexin/fangan/yuan5.png')} alt="" style={{ width: "226px", height: "226px" }} />
            <img className='positionCenter yuan yuan6' src={require('../../static/images/lexin/fangan/yuan6.png')} alt="" style={{ width: "238px", height: "238px" }} />
          </div>
        </div>
        <div style={{ flex: "1 0 0" }}>
          <QueueAnim className="fanganItem"
            type={['right', 'left']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 1}
            duration={600}
          >
            <div key="1">
              <div className="fanganTitle">业务流程合规</div>
              <div className="fanganTips">解决范围：税筹服务</div>
              <div className="fanganTips">特点：合规成本进项+完税到个人</div>
            </div>
          </QueueAnim>
          <QueueAnim className="fanganItem"
            type={['right', 'left']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10 + 350 * 3}
            duration={600}
            style={{ marginTop: "28px" }}
          >
            <div key="1">
              <div className="fanganTitle">技术保障方案</div>
              <div className="fanganTips">应用范围：所有客户</div>
              <div className="fanganTips">有技术能力的客户：快速对接企业APP</div>
              <div className="fanganTips">无技术能力的客户：定制化系统+独立APP</div>
            </div>
          </QueueAnim>
        </div>
      </div>
    )
    //专属解决方案
    let zhuanshu = zhuanshuList.map((obj, index) => (
      <div key={index} className={`zhaunshuItem${index === this.state.zhuanshuActiveIndex ? " active" : ""}`}
        onMouseEnter={() => { this.zhuanshuActive(index) }}
        onMouseLeave={this.zhuanshuActiveOut}
        style={{ backgroundImage: `url(${obj.bgImg})`, backgroundSize: "cover" }}
      >
        <div className="flexCenter flexJCenter zhuanshuImgBox" style={{ width: "50px", height: "50px" }}>
          <img src={obj.img} alt=''
            style={{
              width: obj.size.w > obj.size.h ? "100%" : "auto",
              height: obj.size.w > obj.size.h ? "auto" : "100%",
            }}
          />
        </div>
        <div className="flexColumn flexJCenter zhuanshuTitleWrap">
          <div style={{ fontSize: "17px", color: "#fff", marginTop: "10px" }}>{obj.title}</div>
          <div style={{ fontSize: "12px", color: "#fff" }}>解决方案</div>
        </div>
        <div className="zhuanshuDescp" style={{ fontSize: "12px", color: "#fff" }}>{obj.descp}</div>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className='lexin_page'>
        <PageBg bgSrc={require('../../static/images/lexin/lexin_bg.png')}
          titleM={<span>灵活用工一站式<br />综合服务平台</span>}
          // descp='帮助企业和个人定制薪资筹划、纳税服务方案，合法合规纳税 减轻企业和个人负担'
          descp={
            <p>
              {/* <span>帮助企业和个人定制薪资筹划、纳税服务方案，合法合规纳税</span>
              <br />
              <span>减轻企业和个人负担</span> */}
            </p>
          }
          contentStyle={{ marginLeft: "-240px", width: "560px" }}
          bottomText={[
            "流程正规",
            "合理税筹",
            "安全稳定",
            "结算快捷",
            "操作简便"
          ]}
        />

        {/* 遇到问题 */}
        <div className="wenti overhidden common_item_wrap" style={{ backgroundColor: "#fff", paddingBottom: "80px" }}>
          <div className="flexColumn lexin_titlewrap" style={{ marginBottom: "50px" }}>
            <div className="lexin_titlewrap_title">
              您是否遇到这样的问题？
            </div>
          </div>
          <div style={{ height: "195px" }}>
            {
              window.screen.width > mobileW ?
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <Row type="flex" className='common_wrap'>
                    {wenti}
                  </Row>
                </ScrollOverPack>
                :
                <Row type="flex" className='common_wrap'>
                  {wenti}
                </Row>
            }
            {/* 一堆点 */}
            <div className="flexCenter flexBetween common_wrap allSpanWrap">
              <div className='flexCenter flexAround' style={{ width: "195px" }}>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className="blueSpan"></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
              </div>
              <div className='flexCenter flexAround' style={{ width: "195px" }}>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='greenSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
                <span className='orangeSpan'></span>
              </div>
            </div>
            {/* 一堆点 end */}
          </div>
        </div>

        {/* 为什么选择 */}
        <div className="why overhidden common_item_wrap" style={{ backgroundColor: "#F9F9F9", paddingBottom: "80px" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title">
              为什么选择我们？
            </div>
            <div className="lexin_titlewrap_descp">
              针对不同通信行业场景的零售业务需求，为通信客户提供一站式新零售技术服务
            </div>
          </div>
          <Row type="flex" justify="center" className='common_wrap'>
            {why}
          </Row>
        </div>

        {/* 解决方案 */}
        <div className="fangan overhidden common_item_wrap" style={{ backgroundColor: "#292F59" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title" style={{ color: "#fff" }}>
              综合解决方案
            </div>
            <div className="lexin_titlewrap_descp" style={{ color: "#fff" }}>
              流程正规、安全稳定、操作简单、结算快捷
            </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "304px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  {jiejueDom}
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "304px" }}>
                {jiejueDom}
              </div>
          }
        </div>

        {/* 行业专属解决方案 */}
        <div className="zhuanshu overhidden common_item_wrap" style={{ backgroundColor: "#fff" }}>
          <div className="flexColumn lexin_titlewrap">
            <div className="lexin_titlewrap_title">
              行业专属解决方案
            </div>
            <div className="lexin_titlewrap_descp">
              针对各行业务特性，打造个性化行业解决方案，为您提供一站式的产品服务
            </div>
          </div>
          <div className="common_wrap flexCenter flexJCenter" style={{ margin: "30px auto 50px" }}>
            {zhuanshu}
          </div>
        </div>

      </div >
    )
  }
}

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Lexin)