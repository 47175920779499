import React, { Component } from "react"
//redux
import { connect } from 'react-redux'
import { adActions } from '../redux/models/ad'
//路由
import { Switch, Route } from "react-router-dom"
//页面
import Home from '../pages/Home/Home'
import Product from '../pages/Product/Product'
import Newretail from "../pages/Newretail/Newretail";
import Lexin from "../pages/Lexin/Lexin";
import Aboutus from '../pages/Aboutus/Aboutus'
import JoinUs from "../pages/JoinUs/JoinUs";
import Qualification from '../pages/Qualification/Qualification'
//组件
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

class BasicLayout extends Component {
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentWillMount() {
    if (localStorage.getItem("bldweb") && localStorage.getItem("bldweb") !== "") {
      const { dispatch } = this.props
      dispatch(adActions.loginAgain(localStorage.getItem("bldweb")))
    }
  }
  componentWillUnmount() {
    localStorage.setItem("bldweb", "")
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    return (
      <div>
        <Header />
        <Switch>
          {/* 产品案例 */}
          <Route path="/product" component={Product}></Route>
          {/* 通信新零售 */}
          <Route path="/newretail" component={Newretail}></Route>
          {/* 灵活用工 */}
          <Route path="/lexin" component={Lexin}></Route>
          {/* 关于我们 */}
          <Route path="/aboutus" component={Aboutus}></Route>
          {/* 加入我们 */}
          <Route path="/joinus" component={JoinUs}></Route>
          {/* 企业资质 */}
          <Route path="/qualification" component={Qualification}></Route>
          {/* 主页 */}
          {/* <Route component={Home}></Route> */}
          <Route component={Lexin}></Route>
        </Switch>
        <Footer />
      </div>
    )
  }
}

// export default BasicLayout

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(BasicLayout)


