import React, { Component } from 'react'
//antd组件
import {
  Cascader,
  Input,
  Button,
  message,
  Row, Col,
} from 'antd';
// 组件
// import { Map, Marker } from 'react-amap';
import PageBg from '../../components/PageBg/PageBg'
import Position from '../../components/Position'
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
// 样式
import './Aboutus.less'

const ScrollOverPack = ScrollAnim.OverPack;
const { TextArea } = Input;

export default class Aboutus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //了解便利电
      liaojieList: [
        {
          title: "2014",
          tips: "爱灵活成立"
        }, {
          title: "331+",
          tips: "覆盖城市"
        }, {
          title: "20+",
          tips: "战略合作伙伴"
        }, {
          title: "300000+",
          tips: "服务用户"
        }
      ],
      //企业文化
      wenhuaList: [
        {
          title: "使命",
          tips: "创造价值，创建持续、再生的生态圈",
          img: require("../../static/images/aboutus/wenhua/sm.png"),
          size: { w: 156, h: 161 },
          bgcolor: "#F66F6A",
        }, {
          title: "价值观",
          tips: "正直、尽责、合作、创新",
          img: require("../../static/images/aboutus/wenhua/jzg.png"),
          size: { w: 125, h: 161 },
          bgcolor: "#45A3F4",
        }, {
          title: "经营理念",
          tips: "正直守信、创造价值、坚持拼搏、连接分享",
          img: require("../../static/images/aboutus/wenhua/jyln.png"),
          size: { w: 152, h: 134 },
          bgcolor: "#95CB69",
        }
      ],
      //地址
      center1: { lng: 123.463744, lat: 41.727942 },
      center2: { lng: 116.575978, lat: 39.789776 },
      // 输入数据
      address: [],
      name: '',
      phone: '',
      textareaText: '',
      //留言ajax
      submitting: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //输入绑定
  cascaderChange = (value, selectedOptions) => { //value是个数组["天津"]
    // console.log(value, selectedOptions)
    this.setState({
      address: value
    })
  }
  inputChange = (e) => {
    // console.log(e.target.value)
    let ele = e.target
    let key = ele.getAttribute("data-name")
    let value = ele.value
    this.setState({
      [key]: value
    })
  }
  //提交
  submit = () => {
    // console.log(this.state.address, this.state.name, this.state.phone, this.state.textareaText)
    let address = this.state.address.join(',')
    let name = this.state.name
    let phone = this.state.phone
    let textareaText = this.state.textareaText
    if (address === '' || name === '' || phone === '' || textareaText === '') {
      message.warning('留言信息请填写完整')
      return
    }

    //ajax
    this.setState({ submitting: true })
    setTimeout(() => {
      message.success("留言成功")
      this.setState({
        address: [],
        name: '',
        phone: '',
        textareaText: '',
        submitting: false,
      })
    }, 900)
    // //请求
    // axios.post('/user', {
    //   firstName: 'Fred',
    //   lastName: 'Flintstone'
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      liaojieList, //了解便利电
      wenhuaList, //企业文化
      submitting,
    } = this.state

    //了解便利电
    let liaojie = liaojieList.map((obj, index) => (
      <Col key={index} span={6}>
        <div key={index} className="flexColumn"
          style={{
            height: "180px",
            boxSizing: "border-box",
            borderLeft: index.toString() === "0" ? "none" : "1px solid #D7D6D6",
            justifyContent: "center",
          }}
        >
          <QueueAnim type={index % 2 === 0 ? ['top', 'bottom'] : ['bottom', 'top']}
            leaveReverse={true}
            forcedReplay={true}
            delay={10}
          >
            <div key={index} className="flexColumn">
              <div style={{ fontSize: "42px", color: "#000", fontWeight: "bolder" }}>{obj.title}</div>
              <div style={{ fontSize: "17px" }}>{obj.tips}</div>
            </div>
          </QueueAnim>

        </div>
      </Col>
    ))
    //企业文化
    let wenhua = wenhuaList.map((obj, index) => (
      <div key={index}>
        <QueueAnim type={index % 2 === 0 ? ['top', 'bottom'] : ['bottom', 'top']}
          leaveReverse={true}
          forcedReplay={true}
          delay={100}
        >
          <div key={index} className='flexColumn flexJCenter'
            style={{
              width: "332px",
              height: "236px",
              backgroundColor: obj.bgcolor,
            }}>
            <div className="flexCenter flexJCenter" style={{ width: "70px", height: "70px", marginBottom: "20px" }}>
              <img src={obj.img}
                alt=''
                style={{
                  width: obj.size.w > obj.size.h ? "100%" : "auto",
                  height: obj.size.w > obj.size.h ? "auto" : "100%",
                }}
              />
            </div>
            <div style={{ fontSize: "17px", color: "#fff" }}>{obj.title}</div>
            <div style={{ fontSize: "12px", color: "#fff" }}>{obj.tips}</div>
          </div>
        </QueueAnim>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className='aboutus_page'>
        <PageBg bgSrc={require('../../static/images/contactUs_bg.png')}
          titleM='公司就是一个产品'
          descp='产业互联网时代，互联网产品只有一个'
          isFan={true}
        />

        {/* 了解便利电 */}
        <div className="liaojie overhidden common_item_wrap" style={{ backgroundColor: "#fff" }}>
          <div className="flexColumn aboutus_titlewrap">
            <div className="aboutus_titlewrap_title" style={{ marginBottom: "20px" }}>
              了解爱灵活
            </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "180px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <Row type="flex" className='common_wrap'>
                    {liaojie}
                  </Row>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "180px" }}>
                <Row type="flex" className='common_wrap'>
                  {liaojie}
                </Row>
              </div>
          }
          <div className="common_wrap" style={{ fontSize: "14px", marginTop: "30px", marginBottom: "60px" }}>
            爱灵活创立于2014年，以技术创新、模式创新、应用创新为驱动，聚焦产业行业互联网化的SaaS服务平台领军企业，近百人技术和运营团队，多项自主研发的知识产权和专利。
          </div>
        </div>

        {/* 企业文化 */}
        <div className="wenhua overhidden common_item_wrap" style={{ backgroundColor: "#F9F9F9" }}>
          <div className="flexColumn aboutus_titlewrap">
            <div className="aboutus_titlewrap_title">
              企业文化
              </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "270px", marginTop: "25px", marginBottom: "60px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <Row type="flex" justify="space-between" className='common_wrap'>
                    {wenhua}
                  </Row>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "270px", marginTop: "25px", marginBottom: "60px" }}>
                <Row type="flex" justify="space-between" className='common_wrap'>
                  {wenhua}
                </Row>
              </div>
          }
        </div>

        {/* 联系我们 */}
        <div className="lianxi overhidden common_item_wrap" style={{ backgroundColor: "#fff" }}>
          <div className="flexColumn aboutus_titlewrap">
            <div className="aboutus_titlewrap_title">
              联系我们
              </div>
          </div>

          <div className="common_wrap">
            {/* 沈阳 */}
            <div className='contact_container address'>
              <div className='address_descp'>
                <div className='title'>沈阳总部</div>
                <div className='address_text'>地址：辽宁省沈阳市浑南区金辉街1号德宝大厦702</div>
                <div className='phone'>电话：024-22806888/22817888</div>
                <div className='email'>邮箱：bld@bld365.com</div>
                <div className='zipcode'>邮编：110000</div>
              </div>
              <div style={{ width: 590, height: 270 }}>
                {/* <Map amapkey="73d7dc95d011ab0711c06ee460c8191b"
                  zoom={15} center={this.state.center1}>
                  <Marker position={this.state.center1} />
                </Map> */}
                <img className="img100" src={require("../../static/images/aboutus/map1.png")} alt='' />
              </div>
            </div>
            {/* 北京 */}
            <div className='contact_container address'>
              <div className='address_descp'>
                <div className='title'>北京办事处</div>
                <div className='address_text'>地址：北京市西城区金融大街乙9号威斯汀行政大厦</div>
                <div className='phone'>电话：024-22806888/22817888</div>
                <div className='email'>邮箱：bld@bld365.com</div>
                <div className='zipcode'>邮编：100000</div>
              </div>
              <div style={{ width: 590, height: 270 }}>
                {/* <Map amapkey="73d7dc95d011ab0711c06ee460c8191b"
                  zoom={15} center={this.state.center2}>
                  <Marker position={this.state.center2} />
                </Map> */}
                <img className="img100" src={require("../../static/images/aboutus/map2.png")} alt='' />
              </div>
            </div>
            {/* 在线留言 */}
            <div className='contact_container msg_container'>
              <div style={{ color: '#4A4A4A', fontSize: '19px', padding: '0 50px' }}>在线留言</div>
              <div style={{ padding: '0 85px' }}>
                <div className='flexCenter flexBetween' style={{ margin: '25px 0 20px' }}>
                  {/* <AutoComplete dataSource={dataSource} placeholder='省份' /> */}
                  <Cascader options={Position}
                    changeOnSelect
                    placeholder='省份'
                    value={this.state.address}
                    onChange={this.cascaderChange}
                    disabled={this.state.submitting}
                  />
                  <Input placeholder='姓名'
                    value={this.state.name}
                    onChange={(e) => { this.inputChange(e) }}
                    data-name='name'
                    disabled={this.state.submitting}
                  />
                  <Input placeholder='电话'
                    value={this.state.phone}
                    onChange={(e) => { this.inputChange(e) }}
                    data-name='phone'
                    disabled={this.state.submitting}
                  />
                </div>
                <TextArea placeholder='请输入留言内容'
                  value={this.state.textareaText}
                  onChange={(e) => { this.inputChange(e) }}
                  data-name='textareaText'
                  disabled={this.state.submitting}
                />
              </div>
              <Button onClick={this.submit}
                loading={submitting}
                style={{ fontSize: "14px" }}
              >提交</Button>
            </div>
          </div>
        </div>


      </div >
    )
  }
}
