import React, { Component } from 'react'
// import PropTypes from 'prop-types'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
// 路由
import { Link } from 'react-router-dom'
//antd组件
import { Row, Col } from 'antd'
// 样式
import './Header.less'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //透明度
      headerOpacity: 0,

      tabList: [
        // { text: "首页", link: "/" },
        // { text: "产品案例", link: "/product" },
        // { text: "通信新零售", link: "/newretail" },
        { text: "首页", link: "/" },
        { text: "登录", link: "https://career.vucai.cn/lx/#/user/login" },
        // { text: "加入我们", link: "/joinus" },
        // { text: "企业资质", link: "/qualification" }
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //滚动
  handleScroll = () => {
    let carouselHeight
    if (document.getElementsByClassName("ant-carousel")[0]) {
      carouselHeight = document.getElementsByClassName("ant-carousel")[0].clientHeight
    } else {
      carouselHeight = document.getElementsByClassName("page_bg")[0].clientHeight
    }
    let srcollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    //修改header透明度
    let newOpacity = srcollTop / carouselHeight
    this.setState({ headerOpacity: newOpacity })
  }
  //点击导航
  clickTab = (idx) => {
    const { tabList } = this.state
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))

    let url = tabList[idx].link
    if (idx == 1) {
      window.location.href = url
    }
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      headerOpacity,
      tabList,
    } = this.state
    const { ad } = this.props

    //logo
    // let logoSrc = headerOpacity > 0.7 ? require('../../static/images/LOGO_black.png') : require('../../static/images/LOGO_white.png')
    let logoSrc = require('../../static/images/LOGO.png')
    let tabs = tabList.map((obj, index) => (
      <a key={index}
        className={(headerOpacity > 0.7 ? 'black' : 'white') + (index === ad.curTabIndex ? ' active' : '')}
        onClick={() => { this.clickTab(index) }}
        to={obj.link}
      >
        {obj.text}
      </a>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className='header' style={{ backgroundColor: 'rgba(255, 255, 255, ' + headerOpacity + ')' }}>
        <Row type="flex" align='middle' justify="space-between" style={{ minWidth: "710px", paddingRight: 150 }}>
          <Col sm={{ span: 3, offset: 1 }} md={{ span: 5, offset: 2 }} lg={{ span: 7, offset: 2 }} xl={{ span: 9, offset: 2 }} xxl={{ span: 10, offset: 2 }}>
            <img className='header_logo' src={logoSrc} alt='' />
          </Col>

          <div className='link_wrap flexCenter'>
            {tabs}
          </div>

        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Header)
