import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
//路由
import { Link } from 'react-router-dom';
//变量
import { mobileW } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
// 样式
import './Footer.less'

const ScrollOverPack = ScrollAnim.OverPack;

const QRcodeSrc = require('../../static/images/qr_appcenter.jpg')

class Footer extends Component {
  clickTab = (e) => {
    let ele = e.target
    let idx = Number(ele.getAttribute("data-index"))
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))
  }
  goProductIndex = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(1))
  }
  goLexin = () => {
    // const { dispatch } = this.props
    // dispatch(adActions.changeTabIndex(3))
    window.location.href = "https://lexin.bld365.com/#/user/login"
  }
  render() {
    return (
      <div className='footer'>



        <div className='footer_bot flexCenter flexJCenter'>
          <a style={{ color: '#fff' }}>粤ICP备20009991号-3</a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Footer)

