/**
 * 首页 合作伙伴 不滚动
 */
import React, { Component } from "react"
//redux
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
//路由
// import { Link } from "react-router-dom"
// antd组件
import {
  Carousel, Row,
} from "antd"
// 组件
import SuccessCase from "../../components/SuccessCase/SuccessCase"
// import AppItem from "../../components/AppItem/AppItem"
//变量
import { mobileW, carouselListText } from '../../utils/utils'
//动效
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
// 样式
import "./Home.less"

const ScrollOverPack = ScrollAnim.OverPack
let gundongTimer = null
const transitionTime = 0.2  //单位s

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 走马灯
      carouselList: [
        {
          title: "专业创造价值 服务成就客户",
          descp: "我们每一天都在切身感受着市场和趋势的变化",
          imgBg: require("../../static/images/carousel_bg.png"),
        }
      ],
      //大图和文字item
      bigImgTextList: [
        {
          title: "物流行业赋能",
          descp: "简单易用、自动化办理、数据统计清晰",
          imgSrc: require("../../static/images/home/bigImgText/home01.png")
        }, {
          title: "新零售通信+平台",
          descp: "简单易用、自动化办理、数据统计清晰",
          imgSrc: require("../../static/images/home/bigImgText/home02.png")
        }, {
          title: "爱灵活灵活用工平台",
          descp: "简单易用、自动化办理、数据统计清晰",
          imgSrc: require("../../static/images/home/bigImgText/home03.png")
        }
      ],
      //服务
      fuwuList: [
        {
          title: "实名认证",
          tip: "官方在线、实时认证、标准SDK",
          imgSrc: require("../../static/images/home/fuwu/smrz.png"),
          size: { w: 132, h: 96 },
        }, {
          title: "业务受理",
          tip: "官方平台、实名制合规、全业务实时受理",
          imgSrc: require("../../static/images/home/fuwu/ywsl.png"),
          size: { w: 104, h: 122 },
        }, {
          title: "数字化分销",
          tip: "线上推广裂变、大数据智慧导购",
          imgSrc: require("../../static/images/home/fuwu/szhfx.png"),
          size: { w: 122, h: 120 },
        }, {
          title: "数字化供应链",
          tip: "智能周边、电商平台、本地服务",
          imgSrc: require("../../static/images/home/fuwu/szhgyl.png"),
          size: { w: 107, h: 119 },
        }, {
          title: "数字化营销",
          tip: "快速对接各类渠道触点营销系统",
          imgSrc: require("../../static/images/home/fuwu/szhyx.png"),
          size: { w: 119, h: 119 },
        }, {
          title: "支付服务",
          tip: "数据准确、实时透明、多通道支付",
          imgSrc: require("../../static/images/home/fuwu/zffw.png"),
          size: { w: 125, h: 95 },
        }, {
          title: "快速结酬",
          tip: "T+1自动结酬、秒级到账、安全保障",
          imgSrc: require("../../static/images/home/fuwu/ksjc.png"),
          size: { w: 104, h: 128 },
        }, {
          title: "税筹服务",
          tip: "合规进项、合理税筹、代缴个税",
          imgSrc: require("../../static/images/home/fuwu/scfw.png"),
          size: { w: 110, h: 126 },
        }, {
          title: "供应链金融",
          tip: "保付代理、应付质押、系统对接",
          imgSrc: require("../../static/images/home/fuwu/gyljr.png"),
          size: { w: 125, h: 125 },
        }
      ],
      // //产品下载
      // appList: [
      //   {
      //     title: "京东通信(商用)",
      //     imgSrc: require("../../static/images/app_jdtongxin_com.png"),
      //     qrCodeSrc: require('../../static/images/qr_com.jpg')
      //   }, {
      //     title: "京东通信(物流)",
      //     imgSrc: require("../../static/images/app_jdtongxin_wl.png"),
      //     qrCodeSrc: require('../../static/images/qr_wl.jpg')
      //   }, {
      //     title: "和伙人",
      //     imgSrc: require("../../static/images/app_hehuoren.png"),
      //     qrCodeSrc: require('../../static/images/qr_hehuoren.jpg')
      //   }
      // ],
      //合作伙伴
      partnerList: [
        //真实
        {
          imgSrc: require("../../static/images/home/partner/partner_JD.png"), //京东
          imgW: "114px",
          imgH: "23px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_dianxin.png"), //电信
          imgW: "93px",
          imgH: "28px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_yidong.png"), //移动
          imgW: "84px",
          imgH: "27px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_liantong.png"), //联通
          imgW: "67px",
          imgH: "37px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_wuliu.png"), //京东物流
          imgW: "115px",
          imgH: "27px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_pinganyinhang.png"), //平安银行
          imgW: "93",
          imgH: "32px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_zhaoshangyinhang.png"), //招商银行
          imgW: "101px",
          imgH: "24px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_zhongguoyouzheng.png"), //中国邮政
          imgW: "101px",
          imgH: "24px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_zhongguoguangdayinhang.png"), //中国光大银行
          imgW: "156px",
          imgH: "41px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_shengjingyinhang.png"), //盛京银行
          imgW: "103px",
          imgH: "27px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_xiaomi.png"), //小米
          imgW: "81px",
          imgH: "34px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_meituan.png"), //美团
          imgW: "101px",
          imgH: "37px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_aiqiyi.png"), //爱奇艺
          imgW: "103px",
          imgH: "35px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_shunfengsuyun.png"), //顺丰速运
          imgW: "103px",
          imgH: "38px"
        }, {
          imgSrc: require("../../static/images/home/partner/partner_etc.png"), //ETC
          imgW: "103px",
          imgH: "24px"
        }
        //真实 end
      ],
      itemw: 0,
      partnerIndex: 0,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentWillMount() {
    localStorage.setItem("bldweb", "")
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(0))
    // //合作伙伴滚动
    // this.initPartnerGundong()
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //初始化合作伙伴滚动
  initPartnerGundong = () => {
    let { partnerList } = this.state
    let scrollWrap = document.getElementsByClassName("scrollWrap")[0]

    let wrapw = scrollWrap.clientWidth
    let itemw = wrapw / partnerList.length
    this.setState({ itemw })

    this.beginGundong()
  }
  //开始滚动
  beginGundong = () => {
    let scrollWrap = document.getElementsByClassName("scrollWrap")[0]
    gundongTimer = setInterval(() => {
      let { partnerIndex } = this.state
      scrollWrap.style.transition = `all ${transitionTime}s ease-in-out`
      this.setState({
        partnerIndex: partnerIndex - 1
      }, () => {
        setTimeout(() => {
          let { partnerIndex } = this.state
          if (partnerIndex <= -8) {
            scrollWrap.style.transition = "none"
            this.setState({ partnerIndex: 0 })
          }
        }, transitionTime * 1000) //与transition的时间相同
      })
    }, 2000)
  }
  //停止滚动
  stopGundong = () => {
    window.clearInterval(gundongTimer)
    gundongTimer = null
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      carouselList, //走马灯
      bigImgTextList, //大图文字
      fuwuList, //服务
      // appList, //产品下载
      partnerList, //合作伙伴
      // itemw,
      // partnerIndex,
    } = this.state
    // const { ad } = this.props

    //走马灯
    let carouselDiv = carouselList.map((obj, index) => (
      <div className="carousel_item" key={index}>
        <img className="img_bg" src={obj.imgBg} alt="" />
        <div className="carousel_content positionCenter">
          <div className="carousel_title" style={{ color: "#fff" }}>{obj.title}</div>
          <div className="carousel_descp" style={{ color: "rgba(255,255,255,0.8)" }}>{obj.descp}</div>
        </div>
      </div>
    ));
    //走马灯下面文字
    let carouselDivText = (
      <div className="bottomTextWrap">
        <div className="common_wrap" style={{ display: "flex", height: "100%" }}>
          {
            carouselListText.map((str, index) => (
              <div key={index} className="flexCenter flexJCenter"
                style={{
                  height: "100%",
                  flex: "1 0 0",
                  fontSize: "17px",
                  color: "#EAEAEA",
                  borderRight: "1px solid rgba(255,255,255,0.3)",
                  borderLeft: index.toString() === "0" ? "1px solid rgba(255,255,255,0.3)" : "none",
                }}
              >
                {str}
              </div>
            ))
          }
        </div>
      </div>
    )
    //大图和文字
    let bigImgText = bigImgTextList.map((obj, index) => (
      <div key={index} style={{ width: "332px" }}>
        <QueueAnim type={['bottom', 'top']}
          leaveReverse={true}
          forcedReplay={true}
          delay={10 + index * 100}
        >
          <div key={index}>
            <img src={obj.imgSrc}
              alt=''
              style={{ width: '332px', height: '227px' }}
            />
            <div className='flexColumn flexJCenter'
              style={{ backgroundColor: '#F5F5F6', height: '88px' }}
            >
              <div style={{ fontSize: '17px', color: '#000' }}>{obj.title}</div>
            </div>
          </div>
        </QueueAnim>
      </div>
    ))
    // 服务
    let fuwuDiv = fuwuList.map((obj, index) => (
      <div key={index} style={{ width: "332px" }}>
        <QueueAnim type={["right", "left"]}
          leaveReverse={true}
          forcedReplay={true}
          delay={10 + index * 50}
        >
          <div key={index} className="flexColumn" style={{ padding: "45px 0" }}>
            <div className="flexCenter flexJCenter"
              style={{ width: "58px", height: "58px", marginBottom: "12px" }}>
              <img src={obj.imgSrc}
                alt=""
                style={{
                  width: obj.size.w > obj.size.h ? "100%" : "auto",
                  height: obj.size.w > obj.size.h ? "auto" : "100%",
                }}
              />
            </div>
            <div style={{ fontSize: "14px", color: "#000" }}>{obj.title}</div>
            <div style={{ fontSize: "12px", color: "#4A4A4A" }}>{obj.tip}</div>
          </div>
        </QueueAnim>
      </div>

    ));
    // //产品下载
    // let appDiv = appList.map((obj, index) => (
    //   <div key={index} style={{ width: "204px" }}>
    //     <QueueAnim type={["bottom", "top"]}
    //       leaveReverse={true}
    //       forcedReplay={true}
    //       delay={10 + index * 50}
    //     >
    //       <div className="downappitem" key={index}>
    //         <AppItem key={index}
    //           titleM={obj.title}
    //           imgSrc={obj.imgSrc}
    //           qrCodeSrc={obj.qrCodeSrc}
    //           canShow={true}
    //         />
    //       </div>
    //     </QueueAnim>
    //   </div>
    // ));
    //合作伙伴
    let partnerDiv = partnerList.map((obj, index) => (
      // <div key={index} style={{ width: "194px", margin: "0 10px" }}>
      //   <QueueAnim type={["bottom", "top"]}
      //     leaveReverse={true}
      //     forcedReplay={true}
      //     delay={10 + index * 50}
      //   >
      //     <div className="partner_item" key={index}>
      //       <img
      //         className="positionCenter"
      //         src={obj.imgSrc}
      //         alt=""
      //         style={{ width: obj.imgW, height: obj.imgH }}
      //       />
      //     </div>
      //   </QueueAnim>
      // </div>
      <div key={index}
        className="parter_item_wrap"
      >
        <div className="partner_item" key={index}>
          <img
            className="positionCenter"
            src={obj.imgSrc}
            alt=""
            style={{ width: obj.imgW, height: obj.imgH }}
          />
        </div>
      </div>
    ));
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="home_page">

        <div style={{ position: "relative", minWidth: "1080px" }}>
          <Carousel autoplay>{carouselDiv}</Carousel>
          {carouselDivText}
        </div>


        {/* 大图和文字 */}
        <div className="datu overhidden common_item_wrap" style={{ backgroundColor: "#fff" }}>
          <div className="flexColumn home_titlewrap" style={{ marginTop: "40px", marginBottom: "50px" }}>
            <div className="home_titlewrap_title">
              产业互联网解决方案综合服务商
              </div>
            <div className="home_titlewrap_descp">
              顺应新零售发展趋势，引领通信行业向互联网化和智慧化转型，实现用户满意度和收入双提升
              </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: '380px' }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <Row type='flex' justify='space-between' className='common_wrap'>
                    {bigImgText}
                  </Row>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: '380px' }}>
                <Row type='flex' justify='space-between' className='common_wrap'>
                  {bigImgText}
                </Row>
              </div>
          }
        </div>

        {/* 服务 */}
        <div className="fuwu overhidden common_item_wrap" style={{ backgroundColor: "#F9F9F9" }}>
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title">
              自主研发新零售综合服务平台
            </div>
            <div className="home_titlewrap_descp">
              针对不同通信行业场景的零售业务需求，为通信客户提供一站式新零售技术服务
            </div>
          </div>
          {
            window.screen.width > mobileW ?
              <div style={{ height: "630px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <Row type='flex' justify='space-between' className='common_wrap'>
                    {fuwuDiv}
                  </Row>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "630px" }}>
                <Row type='flex' justify='space-between' className='common_wrap'>
                  {fuwuDiv}
                </Row>
              </div>
          }
        </div>

        {/* 成功案例 */}
        <SuccessCase />

        {/* 产品下载 */}
        {/* <div className="downapp overhidden common_item_wrap" style={{ backgroundColor: "#F9F9F9" }}>
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title">产品下载</div>
            <div className="home_titlewrap_descp">
              通信行业新零售解决方案提供商
            </div>
          </div>

          <div style={{ height: "125px" }}>
            <ScrollOverPack playScale={[0.15, 0.15]}>
              <div className="app_wrap flexCenter flexJCenter">
                {appDiv}
              </div>
            </ScrollOverPack>
          </div>
        </div> */}

        {/* 合作伙伴 */}
        <div className="partner overhidden">
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title">合作伙伴</div>
            <div className="home_titlewrap_descp">
              专业创造价值 服务成就客户
            </div>
          </div>
          {/* {
            window.screen.width > mobileW ?
              <div style={{ height: "56px" }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <div className="partner_wrap flexCenter flexJCenter">
                    {partnerDiv}
                  </div>
                </ScrollOverPack>
              </div>
              :
              <div style={{ height: "56px" }}>
                <div className="partner_wrap flexCenter flexJCenter">
                  {partnerDiv}
                </div>
              </div>
          } */}

          <div className="common_wrap flexCenter flexBetween partner_wrap"
            style={{ flexWrap: "wrap" }}
          >
            {partnerDiv}
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({ //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad
})

export default connect(mapStateToProps)(Home)