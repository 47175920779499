/**
 * spa的主组件App
 */
import React, { Component } from "react"
//redux
import { Provider } from 'react-redux'
import store from './redux/store'
//路由
import { HashRouter as Router } from "react-router-dom"
//layouts
import BasicLayout from './layouts/BasicLayout'
// 样式
import './static/styles/App.css';

class App extends Component {
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    this.preloadImg()
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //加了图片预加载 不确实是否生效。。
  preloadImg = () => {
    let srcArr = [
      require("./static/images/carousel_bg.png"), //首页
      require("./static/images/case_bg.png"), //产品案例
      require("./static/images/newretail_bg.png"), //通信新零售
      require("./static/images/lexin/lexin_bg.png"), //灵活用工
      require("./static/images/contactUs_bg.png"), //关于我们
      require("./static/images/joinUs_bg.png"), //加入我们
      require("./static/images/qualification_bg.png") //企业资质
    ]
    srcArr.forEach((img) => {
      let image = new Image()
      image.src = img
    })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    return (
      <Provider store={store}>
        <Router>
          <BasicLayout />
        </Router>
      </Provider>
    )
  }
}

export default App


