export const mobileW = 600

//走马灯下面文字
export const carouselListText = [
  "产业互联网化专家",
  "高新技术企业",
  "双软认证企业",
  "国际CMMI3级认证",
  "产品覆盖全国"
]
